import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';



const Logo = styled.h1`
  font-size: 30pt;
    height: 150px;
    line-height: 150px;
    position: absolute;
    margin-left: auto;
    text-shadow: 2px 2px #a01c9d;
    z-index: 1;
    color: #fd4af6;
`;

const StyledSVG = styled.svg`
  margin-left: auto;
`;

const Banner = styled.div`
  position: absolute;
  height: 200px;
  display: flex;
  width: 100%;
  height: 146px;
  border-bottom: 1px solid #41555a;
  justify-content: center;
`;

const Contrainer = styled.div`
  width: 1280px;
  height: 100%;
  background-color: red;
  margin: auto;
`

const Portrait = styled.div`
    height: 400px;
    width:300px;
    background-color: blue;
    
`;

export default function AboutMe() {
  const repeat = () => {
    const list = [];
    for (let index = 0; index < 100; index++) {
      for (let j = 0; j < 15; j++) {
        list.push(
          <circle cx={index * 10} cy={j * 10 + Math.random() * 10} r={Math.random() * 3}>
            <animate
              attributeName="cx"
              values={`${Math.floor(Math.random() * 15) * index};${index * Math.floor(Math.random() * 10)
                };${index * Math.floor(Math.random() * 10)}`}
              dur="100s"
              repeatCount="indefinite"
            />
          </circle>
        );
      }
    }
    return list;
  };

  return (
    <div className="App">
      <Banner>
        <Logo>RODCERO</Logo>
        <StyledSVG
          transform="
        rotate(180)
        translate(0,5)
        "
          height="150px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${typeof window !== 'undefined' && window.innerWidth} 150`}
          preserveAspectRatio="none"
        >
          <g fill="#61DAFB">{repeat()}</g>
        </StyledSVG>
      </Banner>
      <Contrainer>
        <Portrait />
      </Contrainer>

    </div>
  );
}
